<script>
  import { createEventDispatcher } from 'svelte';
  import * as basicLightbox from 'basiclightbox';

  import Popup from './Popup.svelte';

  const dispatch = createEventDispatcher();

  export let hasPic = false;
  export let title = "";
  export let image = "";
  export let isApproved = null;
  let detailsValue = '';
  let isApprovalVisible = false;
  let isReprovalVisible = false;

  function handleApproval(data) {
    if(data.detail.result) {
      dispatch('approval', {});
      isApproved = true;
    }
  }

  function handleReproval(data) {
    if (data.detail.result) {
      dispatch('reproval', {
        details: detailsValue
      });
      isApproved = false;
    }
  }

  function openApproval() {
    isApprovalVisible = false;
    isApprovalVisible = true;
  }

  function openReproval() {
    isReprovalVisible = false;
    isReprovalVisible = true;
  }

  function openPhoto() {
    console.log(basicLightbox);
    const instance = basicLightbox.default.create(`
      <img src="${image}" alt="${title}">
    `);
    instance.show();
  }
</script>

<div class="photo-check">
  {#if hasPic}
    <a href="{image}" target="_blank" on:click|preventDefault={openPhoto}>
      <img src={image} alt="" class="photo-check__img">
    </a>
  {:else}
    <div class="no-pic-message">
      Nenhuma imagem recebida.
    </div>
  {/if}
  <span class="photo-check__open-img">Clique para abrir imagem</span>
  <h3 class="photo-check__title">{title}</h3>
  <div class="photo-check__actions">
    {#if isApproved === null && hasPic}
    <button class="photo-check__actions__btn--error" on:click={openReproval}>
      <img src="/img/icon-photo-close.svg" alt="Reprovar">
    </button>
    <button class="photo-check__actions__btn--success" on:click={openApproval}>
      <img src="/img/icon-photo-check.svg" alt="Aprovar">
    </button>
    {/if}
    {#if isApproved === true}
    <div class="photo-check__actions__status photo-check__actions__status--approved">
      <img src="/img/icon-photo-check.svg" alt="Aprovado">
      <span>Validado</span>
    </div>
    {/if}
    {#if isApproved === false}
    <div class="photo-check__actions__status photo-check__actions__status--reproved">
      <img src="/img/icon-photo-close.svg" alt="Reprovado">
      <span>Invalidado</span>
    </div>
    {/if}
  </div>
  <Popup visible={isApprovalVisible} type="prompt" on:prompt={handleApproval} title="Aprovar Foto?" message="Você está prestes a aprovar uma foto, tem certeza disso?" okLabel="Sim, Aprovar." />
  <Popup visible={isReprovalVisible} type="prompt" on:prompt={handleReproval} title="Reprovar Foto?" message="Você está prestes a reprovar uma foto, tem certeza disso?" okLabel="Sim, Reprovar." />
</div>

<style>
  .no-pic-message {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 150px;
    padding: 10px;
    text-align: center;
    background: #eee;
    border-radius: 5px;
  }
  .photo-check {
    width: 215px;
    padding: 8px;
    background: #fff;
    border: 1px solid var(--color-black-20);
    border-radius: 5px;
    box-shadow: 0 3px 6px var(--color-black-05);
    margin: 10px;
  }

  .photo-check__img {
    display: block;
    width: 100%;
    height: 150px;
    object-fit: cover;
    background: #ededed;
    border-radius: 3px;
  }

  .photo-check__open-img {
    display: block;
    font-size: 1.1rem;
    text-align: center;
    color: var(--color-black-35);
    margin: 5px 0;
  }

  .photo-check__title {
    font-size: 1.4rem;
    font-weight: bold;
    margin-bottom: 38px;
    text-align: center;
  }

  .photo-check__actions {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
  }

  .photo-check__actions__btn--success {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #fff;
    width: 38px;
    height: 38px;
    border-radius: 50%;
    padding: 0;
    margin-left: 12px;
    border: 1px solid var(--color-status-success-20);
    transition: all 0.2s linear;
  }

  .photo-check__actions__btn--success:hover {
    border-color: var(--color-status-success);
  }

  .photo-check__actions__btn--error {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #fff;
    width: 38px;
    height: 38px;
    border-radius: 50%;
    padding: 0;
    border: 1px solid var(--color-status-error-20);
    transition: all 0.2s linear;
  }

  .photo-check__actions__btn--error:hover {
    border-color: var(--color-status-error);
  }

  .photo-check__actions__status {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 130px;
    height: 32px;
    border-radius: 50px;
  }

  .photo-check__actions__status--approved {
    background: var(--color-status-success-10);
  }

  .photo-check__actions__status img {
    width: 20px;
    margin-right: 5px;
  }

  .photo-check__actions__status span {
    font-size: 1.1rem;
    font-weight: bold;
    color: var(--color-green-dark);
  }

  .photo-check__actions__status--reproved {
    background: var(--color-status-error-10);
  }

  .photo-check__actions__status--reproved span {
    color: var(--color-status-error);
  }
</style>
