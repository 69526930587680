<script>
    import { onMount, setContext, tick } from 'svelte';
    import { Link, navigateTo } from 'svero';
    import Menu from '../components/Menu.svelte';
    import Heading from '../components/Heading.svelte';
    import Footer from '../components/Footer.svelte';
    import Popup from '../components/Popup.svelte';
    import Loader from '../components/Loader.svelte';
    import { contract } from '../stores/Contract';
    import PicturesService from '../services/Pictures';
    import { toDMY } from '../helpers/Time';
    import { setContract } from '../helpers/SetContract';
    import Pagination from '../components/Pagination.svelte';

    let data = [];
    let isLoading = false;

    async function paginationInitialLoad(amount) {
      return await PicturesService.getContractsWithPendingPictures(amount);
    }

    async function paginationChunkLoad(amount, nextPageToken) {
      return await PicturesService.getContractsWithPendingPictures(amount, nextPageToken);
    }

    async function loadContractAndPhotos(contract) {
      navigateTo(`/${contract}/validar-fotos`);
    }

    async function handlePageChange(e) {
      await tick();
      data = e.detail;
    }

    setContext('pagination_initial_load', paginationInitialLoad);
    setContext('pagination_chunk_load', paginationChunkLoad);
  </script>
  
  <Menu showNav={!!$contract.contract} /> 

  <main>
    <div class="container">
      <Heading main="Veículos em Aprovação" />

      <div class="panel">
        <div class="panel__head">
          <h2 class="panel__head__title">
            Veículos com fotos a aprovar ({data.length})
          </h2>
        </div>
        <table class="grid">
          <thead>
            <tr>
              <th width="20%">Contrato</th>
              <th width="20%">Data</th>
              <th width="50%">Status</th>
              <th width="10%">Ação</th>
            </tr>
          </thead>
        </table>
        <div class="grid__scroller">
          {#if isLoading}
            <Loader local={true} visible={isLoading} absolute={true} />
          {:else}
            {#if data.length > 0}
              <table class="grid">
                <tbody>
                  {#each data as vehicle}
                    <tr>
                      <td width="20%">{vehicle.Contrato}</td>
                      <td width="20%">{toDMY(+new Date(vehicle.AnoMesDia) / 1000)}</td>
                      <td width="50%" class:grid__status--success={vehicle.Status.includes('aprovacao')} class:grid__status--warning={vehicle.Status.includes('upload')} class:grid__status--error={vehicle.Status.includes('todas-pendente-upload')}>{vehicle.Status}</td>
                      <td width="10%"><button class="btn btn--icon" on:click={() => loadContractAndPhotos(vehicle.Contrato)}><img src="/img/icon-forward.svg" alt=""></button></td>
                    </tr>
                  {/each}
                </tbody>
              </table>
            {/if}
          {/if}
        </div>
        <Pagination on:change={handlePageChange} on:loading={(e) => isLoading = e.detail} />
      </div>

    </div>
  </main>
  
  <Footer />

  <style>
  .grid__scroller {
    max-height: calc(100vh - 295px - 150px);
  }
  </style>