<script>
    import { createEventDispatcher } from 'svelte';
    import { fade } from 'svelte/transition';
    import { scale } from '../helpers/Animations';

    const dispatch = createEventDispatcher();
  
    export let visible = false;
    export let title = false;
    export let message = false;
    export let type = 'alert';
    export let okLabel = 'Ok';
    export let cancelLabel = 'Cancelar';

    function ok(e) {
      e.preventDefault();
      visible = false;
      dispatch('prompt', {
        result: true
      });
    }

    function cancel(e) {
      e.preventDefault();
      visible = false;
      dispatch('prompt', {
        result: false
      });
    }
  </script>
  
  
  {#if visible}
  <div class="overlay" transition:fade={{duration: 500}}>
    <div class="popup" transition:scale={{duration: 500}}>
      <h1 class="title">{title}</h1>
      <p class="message">{@html message}</p>
      {#if type === 'alert'}
        <div class="popup-btn-list popup-btn-list--center">
          <button class="btn clean" on:click={ok}>{okLabel}</button>
        </div>
      {/if}
      {#if type === 'prompt'}
        <div class="popup-btn-list">
          <button class="btn btn--outline" on:click={cancel}>{cancelLabel}</button>
          <button class="btn" on:click={ok}>{okLabel}</button>
        </div>
      {/if}
    </div>
  </div>
  {/if}
  
  <style>
    .overlay {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      background: var(--color-black-70);
      z-index: var(--z-8);
      user-select: none;
    }

    .popup {
      width: 395px;
      min-height: 100px;
      border-radius: 5px;
      background: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      box-shadow: 0 0 3px rgba(0,0,0,0.1);
      user-select: none;
      padding: 15px;
    }

    .title {
      font-size: 1.8rem;
      font-weight: bold;
      color: var(--color-black);
      margin-bottom: 13px;
      margin-top: 10px;
    }

    .message {
      font-size: 1.4rem;
      color: var(--color-black);
      margin: 0;
      margin-bottom: 30px;
      text-align: center;
      padding: 0 10px;
    }

    .popup-btn-list {
      display: flex;
      justify-content: space-between;
      min-width: 100%;
      border-radius: 0 0 5px 5px;
      overflow: hidden;
    }

    .popup-btn-list.popup-btn-list--center {
      justify-content: center;
    }

    .btn {
      width: 150px;
      font-size: 1.2rem;
    }
  
    @media screen and (max-width: 480px) {
      .loader-wrapper {
        padding-right: 0;
      }
    }
  </style>