<script>
  import { navigateTo } from 'svero';
  import { onMount, createEventDispatcher } from 'svelte';
  import Popup from './Popup.svelte';

  const dispatch = createEventDispatcher();

  export let pictures = [];
  
  let isApprovalVisible = false;
  let isReprovalVisible = false;

  let picturesMap = false;

  let currentPicture = false;
  let currentName = '';

  let currentPictureWidth = 0;
  let currentPictureHeight = 0;
  let currentPictureOrientation = 'vertical'; // vertical|horizontal
  let currentPictureVirtualOrientation = 'vertical';
  let currentPictureRotation = 0;
  let isLoadingImage = true;

  function setCurrentPicture(name) {
    currentName = name;
    currentPicture = picturesMap[name];
  }

  function openApproval() {
    isApprovalVisible = false;
    isApprovalVisible = true;
  }

  function openReproval() {
    isReprovalVisible = false;
    isReprovalVisible = true;
  }

  function handleApproval(data) {
    if (!data.detail.result) {
      return;
    }

    picturesMap[currentPicture.type].approved = true;
    picturesMap = {...picturesMap};

    dispatch('approval', {
      id: currentPicture.id
    });
  }

  function handleReproval(data) {
    if (!data.detail.result) {
      return;
    }
    
    picturesMap[currentPicture.type].approved = false;
    picturesMap = {...picturesMap};

    dispatch('reproval', {
      id: currentPicture.id
    });
  }

  function handleResetAll() {
    dispatch('resetAll');
  }

  function goBack() {
    navigateTo('/busca-contrato');
  }

  function isPortrait(img) {
    const w = img.naturalWidth || img.width;
    const h = img.naturalHeight || img.height;

    return h > w;
}

  function computeImageData(e) {
    const img = e.target;
    const vertical = isPortrait(img);

    isLoadingImage = true;

    currentPictureWidth = img.naturalWidth || img.width;
    currentPictureHeight = img.naturalHeight || img.height;
    currentPictureOrientation = vertical ? 'vertical' : 'horizontal';
    currentPictureVirtualOrientation = vertical ? 'vertical' : 'horizontal';
    currentPictureRotation = 0;

    setTimeout(() => {
      isLoadingImage = false;
    }, 60);
  }

  function rotateLeft() {
    const vertical = currentPictureVirtualOrientation === 'vertical';
    currentPictureVirtualOrientation = vertical ? 'horizontal' : 'vertical';
    currentPictureRotation -= 90;
  }

  function rotateRight() {
    const vertical = currentPictureVirtualOrientation === 'vertical';
    currentPictureVirtualOrientation = vertical ? 'horizontal' : 'vertical';
    currentPictureRotation += 90;
  }

  function nextPhoto() {
    const photoNames = Object.keys(picturesMap);
    const currentPhotoIndex = photoNames.findIndex(photo => photo === currentName);


    // Caso nao seja a ultima foto, avanca
    if (currentPhotoIndex < photoNames.length - 1) {
      setCurrentPicture(photoNames[currentPhotoIndex + 1]);
    }
  }

  function prevPhoto() {
    const photoNames = Object.keys(picturesMap);
    const currentPhotoIndex = photoNames.findIndex(photo => photo === currentName);


    // Caso não seja a primeira foto, retorna
    if (currentPhotoIndex > 0) {
      setCurrentPicture(photoNames[currentPhotoIndex - 1]);
    }
  }
  
  onMount(() => {
    picturesMap = {
      'rg-cnh-frente': pictures.find(e => e.type === 'rg-cnh-frente'),
      'rg-cnh-verso': pictures.find(e => e.type === 'rg-cnh-verso'),
      'comprovante-endereco': pictures.find(e => e.type === 'comprovante-endereco'),
      'selfie': pictures.find(e => e.type === 'selfie'),
      'clrv': pictures.find(e => e.type === 'clrv'),
      'veiculo-frente': pictures.find(e => e.type === 'veiculo-frente'),
      'veiculo-traseira': pictures.find(e => e.type === 'veiculo-traseira'),
      'veiculo-lateral-esquerda': pictures.find(e => e.type === 'veiculo-lateral-esquerda'),
      'veiculo-lateral-direita': pictures.find(e => e.type === 'veiculo-lateral-direita'),
      'chassi': pictures.find(e => e.type === 'chassi'),
      'motor': pictures.find(e => e.type === 'motor'),
      'quilometragem': pictures.find(e => e.type === 'quilometragem'),
    }
  });

  setTimeout(() => {
    setCurrentPicture('rg-cnh-frente');
  }, 100);
</script>

<div class="check-carousel">
  <!-- <button class="check-carousel__close" on:click={goBack}><img src="/img/icon-close-white.svg" alt="Voltar"></button> -->
  <button on:click={handleResetAll} class="check-carousel__reset">Resetar todas as fotos</button>

  <div class="check-carousel__legends">
    <div class="rotate_actions">
      <button on:click={prevPhoto}>
        <img src="/img/icon-arrow-left-white.svg" alt="Rodar imagem para esquerda">
      </button>
      <button on:click={rotateLeft}>
        <img src="/img/icon-rotate-left.svg" alt="Rodar imagem para esquerda">
      </button>
      <button on:click={rotateRight}>
        <img src="/img/icon-rotate-right.svg" alt="Rodar imagem para direita">
      </button>
      <button on:click={nextPhoto}>
        <img src="/img/icon-arrow-right-white.svg" alt="Rodar imagem para esquerda">
      </button>
    </div>
    <div class="check-carousel__legends__title">Legenda:</div>
    <div class="check-carousel__legends__legend">
      <img src="/img/icon-photo-check.svg" alt="Foto Aprovada">
      <span>Foto Aprovada</span>
    </div>
    <div class="check-carousel__legends__legend">
      <img src="/img/icon-photo-close.svg" alt="Foto Reprovada">
      <span>Foto Reprovada</span>
    </div>
    <div class="check-carousel__legends__legend">
      <img src="/img/icon-photo-warn.svg" alt="Aguardando Aprovação">
      <span>Aguardando Aprovação</span>
    </div>
  </div>

  {#if currentPicture !== false}
    {#if currentPicture.hasPic}
      <div class="check-carousel__image__wrapper">
        <img src={currentPicture.url} alt="" class="check-carousel__image" class:no--animation={isLoadingImage} class:check-carousel__image--vertical={currentPictureVirtualOrientation === 'vertical'} style={`transform: rotate(${currentPictureRotation}deg)`} on:load={computeImageData}>
      </div>

      {#if currentPicture.approved === null || currentPicture.approved === undefined}
        <div class="check-carousel__action">
          <button class="check-carousel__action__btn--reprove" on:click={openReproval}><img src="/img/icon-photo-close.svg" alt="Reprovar"></button>
          <button class="check-carousel__action__btn--approve" on:click={openApproval}><img src="/img/icon-photo-check.svg" alt="Aprovar"></button>
        </div>
      {/if}
    {:else}
      <div class="check-carousel__no_pic">Usuário ainda não enviou imagem.</div>
    {/if}
  {/if}

  {#if picturesMap}
    <div class="check-carousel__actions">
      <div class="check-carousel__actions__action" class:check-carousel__actions__action--needs-approval={picturesMap['rg-cnh-frente'].hasPic && picturesMap['rg-cnh-frente'].approved === undefined} class:check-carousel__actions__action--approved={picturesMap['rg-cnh-frente'].approved} class:check-carousel__actions__action--reproved={picturesMap['rg-cnh-frente'].approved === false} class:check-carousel__actions__action--active={currentName === 'rg-cnh-frente'} on:click={() => setCurrentPicture('rg-cnh-frente')}>
        <span>RG/CNH<br>Frente</span>
      </div>
      <div class="check-carousel__actions__action" class:check-carousel__actions__action--needs-approval={picturesMap['rg-cnh-verso'].hasPic && picturesMap['rg-cnh-verso'].approved === undefined} class:check-carousel__actions__action--approved={picturesMap['rg-cnh-verso'].approved} class:check-carousel__actions__action--reproved={picturesMap['rg-cnh-verso'].approved === false} class:check-carousel__actions__action--active={currentName === 'rg-cnh-verso'} on:click={() => setCurrentPicture('rg-cnh-verso')}>
        <span>RG/CNH<br>Verso</span>
      </div>
      <div class="check-carousel__actions__action" class:check-carousel__actions__action--needs-approval={picturesMap['comprovante-endereco'].hasPic && picturesMap['comprovante-endereco'].approved === undefined} class:check-carousel__actions__action--approved={picturesMap['comprovante-endereco'].approved} class:check-carousel__actions__action--reproved={picturesMap['comprovante-endereco'].approved === false} class:check-carousel__actions__action--active={currentName === 'comprovante-endereco'} on:click={() => setCurrentPicture('comprovante-endereco')}>
        <span>Comprovante<br>de Endereço</span>
      </div>
      <div class="check-carousel__actions__action" class:check-carousel__actions__action--needs-approval={picturesMap['selfie'].hasPic && picturesMap['selfie'].approved === undefined} class:check-carousel__actions__action--approved={picturesMap['selfie'].approved} class:check-carousel__actions__action--reproved={picturesMap['selfie'].approved === false} class:check-carousel__actions__action--active={currentName === 'selfie'} on:click={() => setCurrentPicture('selfie')}>
        <span>Selfie</span>
      </div>
      <div class="check-carousel__actions__action" class:check-carousel__actions__action--needs-approval={picturesMap['clrv'].hasPic && picturesMap['clrv'].approved === undefined} class:check-carousel__actions__action--approved={picturesMap['clrv'].approved} class:check-carousel__actions__action--reproved={picturesMap['clrv'].approved === false} class:check-carousel__actions__action--active={currentName === 'clrv'} on:click={() => setCurrentPicture('clrv')}>
        <span>Registro de Licenciamento<br>de Veículos</span>
      </div>
      <div class="check-carousel__actions__action" class:check-carousel__actions__action--needs-approval={picturesMap['veiculo-frente'].hasPic && picturesMap['veiculo-frente'].approved === undefined} class:check-carousel__actions__action--approved={picturesMap['veiculo-frente'].approved} class:check-carousel__actions__action--reproved={picturesMap['veiculo-frente'].approved === false} class:check-carousel__actions__action--active={currentName === 'veiculo-frente'} on:click={() => setCurrentPicture('veiculo-frente')}>
        <span>Frente<br>Veículo</span>
      </div>
      <div class="check-carousel__actions__action" class:check-carousel__actions__action--needs-approval={picturesMap['veiculo-traseira'].hasPic && picturesMap['veiculo-traseira'].approved === undefined} class:check-carousel__actions__action--approved={picturesMap['veiculo-traseira'].approved} class:check-carousel__actions__action--reproved={picturesMap['veiculo-traseira'].approved === false} class:check-carousel__actions__action--active={currentName === 'veiculo-traseira'} on:click={() => setCurrentPicture('veiculo-traseira')}>
        <span>Traseira<br>Veículo</span>
      </div>
      <div class="check-carousel__actions__action" class:check-carousel__actions__action--needs-approval={picturesMap['veiculo-lateral-esquerda'].hasPic && picturesMap['veiculo-lateral-esquerda'].approved === undefined} class:check-carousel__actions__action--approved={picturesMap['veiculo-lateral-esquerda'].approved} class:check-carousel__actions__action--reproved={picturesMap['veiculo-lateral-esquerda'].approved === false} class:check-carousel__actions__action--active={currentName === 'veiculo-lateral-esquerda'} on:click={() => setCurrentPicture('veiculo-lateral-esquerda')}>
        <span>Lateral<br>Esquerda</span>
      </div>
      <div class="check-carousel__actions__action" class:check-carousel__actions__action--needs-approval={picturesMap['veiculo-lateral-direita'].hasPic && picturesMap['veiculo-lateral-direita'].approved === undefined} class:check-carousel__actions__action--approved={picturesMap['veiculo-lateral-direita'].approved} class:check-carousel__actions__action--reproved={picturesMap['veiculo-lateral-direita'].approved === false} class:check-carousel__actions__action--active={currentName === 'veiculo-lateral-direita'} on:click={() => setCurrentPicture('veiculo-lateral-direita')}>
        <span>Lateral<br>Direita</span>
      </div>
      <div class="check-carousel__actions__action" class:check-carousel__actions__action--needs-approval={picturesMap['chassi'].hasPic && picturesMap['chassi'].approved === undefined} class:check-carousel__actions__action--approved={picturesMap['chassi'].approved} class:check-carousel__actions__action--reproved={picturesMap['chassi'].approved === false} class:check-carousel__actions__action--active={currentName === 'chassi'} on:click={() => setCurrentPicture('chassi')}>
        <span>Chassi</span>
      </div>
      <div class="check-carousel__actions__action" class:check-carousel__actions__action--needs-approval={picturesMap['motor'].hasPic && picturesMap['motor'].approved === undefined} class:check-carousel__actions__action--approved={picturesMap['motor'].approved} class:check-carousel__actions__action--reproved={picturesMap['motor'].approved === false} class:check-carousel__actions__action--active={currentName === 'motor'} on:click={() => setCurrentPicture('motor')}>
        <span>Capô<br>Aberto</span>
      </div>
      <div class="check-carousel__actions__action" class:check-carousel__actions__action--needs-approval={picturesMap['quilometragem'].hasPic && picturesMap['quilometragem'].approved === undefined} class:check-carousel__actions__action--approved={picturesMap['quilometragem'].approved} class:check-carousel__actions__action--reproved={picturesMap['quilometragem'].approved === false} class:check-carousel__actions__action--active={currentName === 'quilometragem'} on:click={() => setCurrentPicture('quilometragem')}>
        <span>Painel</span>
      </div>
    </div>
  {/if}
</div>

<Popup visible={isApprovalVisible} type="prompt" on:prompt={handleApproval} title="Aprovar Foto?" message="Você está prestes a aprovar uma foto, tem certeza disso?" okLabel="Sim, Aprovar." />
<Popup visible={isReprovalVisible} type="prompt" on:prompt={handleReproval} title="Reprovar Foto?" message="Você está prestes a reprovar uma foto, tem certeza disso?" okLabel="Sim, Reprovar." />

<style>
  .check-carousel {
    position: fixed;
    top: 85px;
    left: 0;
    width: 100%;
    height: calc(100% - 85px - 62px);
    display: flex;
    padding-bottom: 90px;
    z-index: var(--z-1);
    background: var(--color-black-70);
  }

  .check-carousel__actions {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 90px;
    display: flex;
    background: var(--color-black-70);
    border-top: 1px solid var(--color-black-70);
  }

  .check-carousel__actions__action {
    position: relative;
    flex: 1;
    padding: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all 0.15s linear;
  }

  .check-carousel__actions__action:hover {
    background: rgba(255, 255, 255, 0.2);
  }

  .check-carousel__actions__action:active {
    background: rgba(255, 255, 255, 0.4);
  }

  .check-carousel__actions__action--active {
    background: #fff !important;
  }

  .check-carousel__actions__action--approved:before {
    content: '';
    position: absolute;
    background: url(/img/icon-photo-check.svg) no-repeat center;
    width: 20px;
    height: 20px;
    background-size: 100% auto;
    border-radius: 50%;
    left: 10px;
    top: 10px;
  }

  .check-carousel__actions__action--reproved:before {
    content: '';
    position: absolute;
    background: url(/img/icon-photo-close.svg) no-repeat center;
    width: 20px;
    height: 20px;
    background-size: 100% auto;
    border-radius: 50%;
    left: 10px;
    top: 10px;
  }

  .check-carousel__actions__action--needs-approval:before {
    content: '';
    position: absolute;
    background: url(/img/icon-photo-warn.svg) no-repeat center;
    width: 20px;
    height: 20px;
    background-size: 80% auto;
    border-radius: 50%;
    left: 10px;
    top: 10px;
  }

  .check-carousel__actions__action--active span {
    color: var(--color-black) !important;
  }

  .check-carousel__actions__action:not(:last-child) {
    border-right: 1px solid var(--color-black-70);
  }

  .check-carousel__actions__action span {
    display: block;
    text-align: center;
    font-weight: bold;
    color: #fff;
    font-size: 12px;
    line-height: 16px;
  }

  .check-carousel__image {
    max-width: 100%;
    max-height: 100%;
    background: #fff;
    transition: all 0.2s ease-in-out;
  }

  .check-carousel__image.check-carousel__image--vertical {
    max-width: calc(100vh - 260px);
    max-height: 100%;
  }

  .check-carousel__action {
    position: absolute;
    left: 50%;
    bottom: 150px;
    display: flex;
    background: #fff;
    padding: 15px;
    border-radius: 5px;
    transform: translateX(-50%);
    z-index: var(--z-6);
    box-shadow: 0 5px 10px rgba(0,0,0,0.3);
  }

  .check-carousel__action__btn--approve {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #fff;
    width: 38px;
    height: 38px;
    border-radius: 50%;
    padding: 0;
    margin-left: 12px;
    border: 1px solid var(--color-status-success-20);
    transition: all 0.2s linear;
  }

  .check-carousel__action__btn--approve:hover {
    border-color: var(--color-status-success);
  }

  .check-carousel__action__btn--reprove {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #fff;
    width: 38px;
    height: 38px;
    border-radius: 50%;
    padding: 0;
    border: 1px solid var(--color-status-error-20);
    transition: all 0.2s linear;
  }

  .check-carousel__action__btn--reprove:hover {
    border-color: var(--color-status-error);
  }

  .check-carousel__reset {
    position: absolute;
    right: 10px;
    top: 10px;
    z-index: 2;
    background: #fff;
    color: var(--color-black);
    border-radius: 3px;
    border: none;
    font-weight: bold;
    padding: 10px 15px;
    font-size: 14px;
  }

  .check-carousel__close {
    position: absolute;
    left: 10px;
    top: 10px;
    background: transparent;
    border: none;
    padding: 10px;
    cursor: pointer;
  }

  .check-carousel__close img {
    width: 30px;
  }

  .check-carousel__legends {
    position: absolute;
    left: 10px;
    bottom: 100px;
    z-index: 2;
    padding: 10px;
    background: var(--color-black-70);
    border-radius: 5px;
  }

  .check-carousel__legends__title {
    font-size: 14px;
    margin-bottom: 10px;
    color: #fff;
  }

  .check-carousel__legends__legend {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
  }

  .check-carousel__legends__legend:last-child {
    margin-bottom: 0;
  }

  .check-carousel__legends__legend img {
    width: 16px;
    margin-right: 5px;
  }

  .check-carousel__legends__legend span {
    font-weight: bold;
    font-size: 12px;
    color: #fff;
  }

  .check-carousel__no_pic {
    padding: 10px;
    border-radius: 5px;
    border: 1px solid rgba(255, 255, 255, 0.4);
    color: #fff;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate3d(-50%, -50%, 0);
  }

  .check-carousel__image__wrapper {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .no--animation {
    transition: none !important;
  }

  .rotate_actions {
    display: flex;
    justify-content: space-around;
    padding: 0px 0 10px 0;
    border-bottom: 1px solid rgba(255,255,255,0.2);
    margin-bottom: 10px;
  }

  .rotate_actions button {
    background: rgba(0,0,0,0.3);
    border-radius: 5px;
    width: 38px;
    height: 38px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid rgba(255,255,255,0.2);
    cursor: pointer;
  }

  .rotate_actions button:hover {
    background: rgba(0,0,0,0.6);
    border: 1px solid rgba(255,255,255,0.4);
  }

  .rotate_actions button:active {
    transform: scale(0.95);
  }
</style>