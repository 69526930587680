<script>
	import { Link } from 'svero';
	import Menu from '../components/Menu.svelte';
	import Heading from '../components/Heading.svelte';
	import Footer from '../components/Footer.svelte';
	import Popup from '../components/Popup.svelte';
	import Loader from '../components/Loader.svelte';
	import { contract } from '../stores/Contract';
	import ContractService from '../services/Contract';

	let contractField = '';
  let isLoading = false;
  const contractOrImeiRegex = /[^0-9\-]/g;

	async function handleForm() {
		isLoading = true;
		const contractData = await ContractService.getContract(contractField);

		if (!contractData) {
			isLoading = false;
			return;
    }
    
    let phoneData = false;

    if ((contractData.Dispositivo || {}).Imei) {
      phoneData = await ContractService.getPhoneData((contractData.Dispositivo || {}).Imei);
    }

		$contract = {
			clientName: contractData.Nome || 'Usuário',
			contractNumber: contractData.Contrato,
			imei: contractData.Dispositivo.Imei,
			plate: contractData.Veiculo.Placa,
			vehicle: contractData.Veiculo.Modelo,
			picturesStatus: contractData.StatusFotos,
      telefonia: phoneData || {},
      raw: contractData,
		};

		isLoading = false;
	}

	function resetContract() {
		isLoading = true;

		setTimeout(() => {
			contractField = '';
			contract.reset();
			isLoading = false;
		}, 400);
  }
  
  function handleKeypress(e) {
    if (contractOrImeiRegex.exec(e.key)) {
      e.preventDefault();
      return false;
    }
  }

  function handleInput(e) {
    contractField = e.target.value.replace(contractOrImeiRegex, '');
  }
</script>

<style>
	main {
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
	}

	.search-contract {
		text-align: center;
	}

	p {
		margin-top: 15px;
		font-size: 1.5rem;
		line-height: 1.8rem;
	}

	.field-wrapper {
		margin: 25px auto 0;
		max-width: 256px;
	}

	.btn.btn--outline {
		margin-top: 25px;
	}

	.general-actions {
		margin-top: 40px;
	}

	.general-actions :global(.btn) {
		min-width: 180px;
	}

	.general-actions :global(.btn + .btn) {
		margin-left: 5px;
	}
</style>

<Menu showNav={!!$contract.contract} />

<Loader visible={isLoading} />

<main>
	{#if $contract.contract}
		<div class="search-contract">
			<Heading main="Contrato/IMEI Selecionado." centered={true} />
			<p>
				Você está com o contrato
				<b>{$contract.contractNumber}</b>
				selecionado e agora pode navegar pelo sistema.
			</p>
			<button class="btn btn--outline" on:click={resetContract}>Buscar novo Contrato/IMEI</button>
		</div>
	{:else}
		<div class="search-contract">
			<Heading main="Buscar Contrato ou IMEI" centered={true} />
			<p>Para usar o sistema, você precisa primeiro selecionar um contrato/IMEI:</p>
			<form on:submit|preventDefault={handleForm}>
				<div class="field-wrapper">
					<input type="text" class="field" bind:value={contractField} on:keypress={handleKeypress} on:input={handleInput} />
					<button class="btn btn--icon">
						<img src="/img/icon-arrow-right.svg" alt="" />
					</button>
				</div>
			</form>
		</div>
	{/if}
	<div class="general-actions">
		<Link class="btn btn--outline" href="/veiculos-aprovar">Veículos a Aprovar</Link>
		<Link class="btn btn--outline" href="/buscar-furto-roubo">Veículos em Furto e Roubo</Link>
    <Link class="btn btn--outline" href="/buscar-erros">Eventos de Veículos</Link>
    <Link class="btn btn--outline" href="/eventos/nao-associados">Dispositivos não Associados</Link>
	</div>
</main>

<Footer />

