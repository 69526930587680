<script>
  import { onMount, createEventDispatcher } from 'svelte';
  import GoogleMapsLoader from 'google-maps';
  import { light, clean, whiteBlue, neon, matrix } from '../helpers/MapStyles';

  const dispatch = createEventDispatcher();

  export let lat = -19.9288907;
  export let long = -43.950966;
  let map;

  function centerOnVehicle() {
    if (!map) {
      return;
    }

    map.setCenter({
      lat: lat,
      lng: long,
    });
  }

  function zoomIn() {
    map.setZoom(map.getZoom() + 1);
  }

  function zoomOut() {
    map.setZoom(map.getZoom() - 1);
  }

  onMount(() => {
    GoogleMapsLoader.LANGUAGE = 'pt-BR';
    GoogleMapsLoader.REGION = 'BR';
    GoogleMapsLoader.KEY = 'AIzaSyBN8DDdqTMRPfqxwdMbKMxqXeMkFy-WhGA';
    GoogleMapsLoader.load((google) => {
      map = new google.maps.Map(document.querySelector('.map'), {
        center: {lat, lng: long},
        zoom: 14,
        // disableDefaultUI: true,
        panControl: false,
        zoomControl: false,
        mapTypeControl: true,
        scaleControl: false,
        streetViewControl: true,
        overviewMapControl: false,
        rotateControl: false
        // styles: neon
      });
      dispatch('ready', { map });
    });
  });
</script>

<div class="map-wrapper">
  <div class="map">
    <slot />
  </div>
  <div class="zoom-in" on:click={zoomIn}>
    +
  </div>

  <div class="zoom-out" on:click={zoomOut}>
    -
  </div>

  <div class="center-vehicle" on:click={centerOnVehicle}>
    <img src="/img/icon-car.svg" alt="">
  </div>
</div>

<style>
  .zoom-in {
    width: 25px;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: 160px;
    right: 70px;
    border-radius: 5px;
    background: #fff;
    font-weight: bold;
    font-size: 15px;
    color: var(--color-blue);
    box-shadow: 0 2px 4px rgba(0,0,0,0.2);
    cursor: pointer;
    user-select: none;
  }

  .zoom-out {
    width: 25px;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: 130px;
    right: 70px;
    border-radius: 5px;
    background: #fff;
    font-weight: bold;
    font-size: 15px;
    color: var(--color-blue);
    box-shadow: 0 2px 4px rgba(0,0,0,0.2);
    cursor: pointer;
    user-select: none;
  }

  .map-wrapper {
    position: relative;
    margin-right: 290px;
    flex: 1;
    display: flex;
    flex-direction: column;
  }

  @media screen and (max-width: 600px) {
    .map-wrapper {
      margin-right: 0;
    }
  }

  .map {
    position: relative;
    flex: 1;
  }

  .center-vehicle {
    position: absolute;
    bottom: 50px;
    right: 50px;
    padding: 20px;
    z-index: 10;
    border-radius: 50%;
    background: #fff;
    box-shadow: 0 2px 5px rgba(0,0,0,0.2), 0 5px 10px rgba(0,0,0,0.1);
    cursor: pointer;
    transition: all 0.2s ease-in-out;
  }

  .center-vehicle:hover {
    transform: scale(1.05);
  }

  .center-vehicle:active {
    transform: scale(0.95);
  }
</style>