<script>
	import { navigateTo } from 'svero';
	import { contract } from '../stores/Contract';

	function goto() {
		navigateTo('/busca-contrato');
	}
</script>

<style>
	.contract {
		display: flex;
		align-items: center;
		margin-left: 20px;
		padding: 5px 13px;
		border-radius: 3px;
		background: #fff;
		font-size: 12px;
		font-weight: bold;
    color: var(--color-blue);
    cursor: text;
  }
  
  @media screen and (max-width: 600px) {
    .contract {
      max-width: 160px;
    }
  }

	.contract--red {
		background: var(--color-status-error);
		color: #fff;
		cursor: default;
	}

	.contract img {
		margin-right: 5px;
    cursor: pointer;
    user-select: none;
	}
</style>

<div class="contract" class:contract--red={!$contract.contract} aria-label="Clique no chip para buscar contrato" data-balloon-pos="down">
	{#if $contract.contract}
		<img src="/img/icon-contract.svg" alt="" on:click={goto} />
		<span>{$contract.contractNumber} | {$contract.clientName} | {($contract.telefonia || {}).NumeroTelefone || ''}</span>
	{:else}
		<img src="/img/icon-contract-white.svg" alt="" on:click={goto} />
		<span>Sem Contrato</span>
	{/if}
</div>
